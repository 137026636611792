<template>
  <div class="no-padding" v-if="item">
    <memlist-text-input
      id="confirm_text_box"
      name="confirm_text_box"
      :title="item.name"
      layout="vertical"
      :validation_function="validate_confirm_text"
      validation_type="TEXT"
      size="lg"

      v-model="input_text"

      :placeholder="item.name"
      :required="item.is_mandatory"
      
      @validated="input_validated"
    />
  </div>
</template>

<script>
export default {
  name: 'QuestionTextInputOption',
  props: {
    item: Object,
    modelValue: String
  },

  data() {
    return {
      input_text: this.modelValue || ''
    };
  },

  watch: {
    modelValue(newVal) {
      this.input_text = newVal;
    },
    input_text(newVal) {
      this.$emit('selected', newVal); // Sync with parent
    }
  },

  methods: {
    input_validated() {},
    validate_confirm_text() {
      return true;
    }
  }
};
</script>
