<template>

    <memlist-modal
      size="lg"
      ref="login-form"
      :visible="show_modal" @close="show_modal = false"
      hide-footer
      >

      <MemberTabbedLogin 
        style="margin: auto;"
        :is_modal="true"
        @authenticated="authenticated"
      />

  </memlist-modal>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { mapGetters } from 'vuex';
import MemberTabbedLogin from '@/view/pages/members/auth/login/MemberTabbedLogin.vue';

export default {

  name: 'MemberLoginModal',

  emits: ['authenticated'],
  mixins: [ toasts ],

  components: {
    MemberTabbedLogin
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
    ...mapGetters(['currentCompanyId']),

  },

  methods: {

    cancelled() {
      this.show_modal = false;
      this.$emit('cancelled');
    },

    /*
    data = { member, token, refresh_token, company }
    */
    authenticated(data) {
      this.$emit('authenticated', data);
      this.show_modal = false;
    },

    show() {
      this.show_modal = true;
    },

    hide() {
      this.show_modal = false;
    },

  },

  mounted() {


  },

  data() {
    return {
      show_modal: false,
      creating: true,
    };
  }
};

</script>


<style lang="scss" scoped>

@import "@/assets/sass/components/forms/_common_modals.scss";
</style>
