<template>
  <div class="no-padding">

    <label class="question-label">{{ item.name }}</label>

    <div
      v-for="(i, index) in item.options"
      :key="index"
      :class="['box', { 'selected-box': selected === (i.value || i.name) }]"
      @click="selected = (i.value || i.name)"
    >

      <div class="box-header">
        <label class="custom-radio">
          <input
            :name="`radio-select-${item.id}`"
            type="radio"
            :value="i.value || i.name"
            v-model="selected"
            @change="change_selected"
          />
          <span class="custom-radio-button"></span>
          <span class="name-label">{{ i.text || i.name }}</span>
        </label>
      </div>
    </div>

    <memlist-text-input
      v-if="show_text"
      id="text_unlocked"
      name="text_unlocked"
      title="Fritext"
      layout="vertical"
      validation_type="TEXT"
      size="lg"
      v-model="input_text"

      placeholder="Fritext"
      :required="false"
    />

  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';


export default {

  name: 'QuestionRadioOptions',
  props: ['item'],
  
  mixins: [ toasts ],

  components: {
    
  },

  watch: {

    item: {
      deep: true,
      handler(val) {
        this.selected = null;
      }
    },
    input_text: {
      deep: true,
      handler() {

        if (this.input_text) {
          this.$emit('selected', this.selected, this.input_text);
        }

      }
    },
    selected: {
      deep: true,
      handler(val) {
        this.$emit('selected', val, this.input_text);

        if (val) {
          const unlock_text = this.item.options.find(i => i.unlock_text);

          this.show_text = !!unlock_text;
        }
        else {
          this.show_text = false;
        }
      }
    },
  },

  computed: {
    
  },

  mounted() {
    
  },

  methods: {
    change_selected() {
      this.$emit('selected', this.selected);
    }
  },

  data() {
    return {
      selected: null,
      show_text: false,
      input_text: null,
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.question-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}


.name-label {
  font-weight: bold;
  margin-left: 5px;
}

.item-selector {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.company-title {
  font-size: 22px;
  margin: 20px 0;
  text-align: left;
  color: #333;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
}

/* Grid for items */
.item-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns on desktop */
  gap: 20px;
}

.box {
  padding: 20px;
  padding-bottom: 10px;
  border: 1px solid #ececec;
  border-radius: 2px;
  position: relative;
  transition: border-color 0.3s;
  cursor: pointer;
}

.selected-box {
  border-color: #2D9BF0; /* Highlight color when selected */
  background-color: #f6fdff;
}

.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Custom Radio Button */
.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio-button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid gray;
  background-color: white;
  margin-right: 10px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s, border-color 0.3s;
}

.custom-radio input:checked + .custom-radio-button {
  background-color: #2D9BF0; /* Blue background for selected radio */
  border-color: #2D9BF0;
}

.custom-radio-button::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: white;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-radio input:checked + .custom-radio-button::after {
  opacity: 1;
}

@media (max-width: 768px) {
  /* 1 column layout on mobile */
  .item-grid {
    grid-template-columns: 1fr;
  }

  .item-selector {
    padding: 0px;
  }
}

</style>
