<template>
  <div class="no-padding">
    <label class="question-label">{{ item.name }}</label>

    <div
      v-for="(i, index) in item.options"
      :key="index"
      :class="['box', { 'selected-box': selected.includes(i.value || i.name) }]"
      @click="toggleSelection(i.value || i.name)"
    >
      <div class="box-header">
        <label class="exp-checkbox-label" @click.stop>
          <input
            type="checkbox"
            :value="i.value || i.name"
            v-model="selected"
            @click.stop
          />
          <span class="exp-checkbox-custom"></span>
          <span class="exp-checkbox-text">{{ i.text || i.name }}</span>
        </label>
      </div>
    </div>

    <memlist-text-input
      v-if="show_text"
      id="text_unlocked"
      name="text_unlocked"
      title="Fritext"
      layout="vertical"
      validation_type="TEXT"
      size="lg"

      v-model="input_text"

      placeholder="Fritext"
      :required="false"
    />
  </div>
</template>

<script>
export default {
  name: 'QuestionCheckboxOptions',
  props: ['item'],

  data() {
    return {
      selected: [],
      show_text: false,
      input_text: null,
    };
  },

  watch: {
    item: {
      deep: true,
      handler() {
        this.selected = [];
      }
    },
    input_text: {
      deep: true,
      handler() {

        if (this.input_text) {
          this.$emit('selected', this.selected, this.input_text);
        }

      }
    },
    selected: {
      deep: true,
      handler() {

        this.$emit('selected', this.selected, this.input_text);

        if (this.selected) {
          const unlock_text = this.item.options.find(i => i.unlock_text);

          this.show_text = !!unlock_text;
        }
        else {
          this.show_text = false;
        }
      }
    }
  },

  methods: {
    toggleSelection(value) {
      if (this.selected.includes(value)) {
        this.selected = this.selected.filter(v => v !== value);
      } else {
        this.selected.push(value);
      }
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.question-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Box wrapper */
.box {
  padding: 15px;
  padding-top: 20px;
  border: 1px solid #ececec;
  transition: border-color 0.3s, background-color 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.selected-box {
  border-color: #0085eb;
  background-color: #f6fdff;
}

.box-header {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Checkbox styling from TableCheckbox */
.exp-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.exp-checkbox-label input {
  opacity: 0;
  width: 0;
  height: 0;
}

.exp-checkbox-custom {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 2px solid #0085eb;
  border-radius: 4px;
  position: relative;
  transition: background-color 0.2s, border-color 0.2s;
}

.exp-checkbox-label input:checked ~ .exp-checkbox-custom {
  background-color: #0085eb;
  border-color: #0085eb;
}

.exp-checkbox-custom::after {
  content: "";
  position: absolute;
  display: none;
}

.exp-checkbox-label input:checked ~ .exp-checkbox-custom::after {
  display: block;
}

.exp-checkbox-custom::after {
  left: 6px;
  top: 2px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.exp-checkbox-text {
  margin-left: 12px;
  font-size: 14px;
}
</style>
