<template>
  <div class="no-padding">

    <div v-if="item">
      <QuestionDateOption 
        v-if="item.type === 'DATE'"
        :item="item"
        @selected="date_selected"
      />

      <QuestionCheckboxOptions 
        v-if="item.type === 'CHECKBOX'"
        :item="item"
        @selected="checkbox_selected"
      />

      <QuestionDropdownOption 
        v-if="item.type === 'SELECT'"
        :item="item"
        @selected="select_selected"
      />

      <QuestionFileUploadOption
        v-if="item.type === 'FILE'"
        :item="item"
        @selected="file_selected"
      />

      <QuestionRadioOptions 
        v-if="item.type === 'RADIO'"
        :item="item"
        @selected="radio_selected"
      />

      <QuestionTextInputOption
        v-if="item.type === 'TEXT'"
        :item="item"
        @selected="text_selected"
      />
    </div>

    
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';

import QuestionCheckboxOptions from '@/view/components/questions/QuestionCheckboxOptions.vue';
import QuestionDropdownOption from '@/view/components/questions/QuestionDropdownOption.vue';
import QuestionFileUploadOption from '@/view/components/questions/QuestionFileUploadOption.vue';
import QuestionRadioOptions from '@/view/components/questions/QuestionRadioOptions.vue';
import QuestionTextInputOption from '@/view/components/questions/QuestionTextInputOption.vue';
import QuestionDateOption from '@/view/components/questions/QuestionDateOption.vue';


export default {

  name: 'QuestionAsked',
  props: ['item'],
  
  mixins: [ toasts ],

  components: {
    QuestionCheckboxOptions,
    QuestionDropdownOption,
    QuestionFileUploadOption,
    QuestionRadioOptions,
    QuestionTextInputOption,
    QuestionDateOption
  },

  watch: {

  },

  computed: {
    
  },

  mounted() {
    
  },

  methods: {
    checkbox_selected(selected, text) {
      this.$emit('selected', this.item, selected, text);
    },
    radio_selected(selected, text) {
      this.$emit('selected', this.item, selected, text);
    },
    text_selected(selected) {
      this.$emit('selected', this.item, selected);
    },
    date_selected(selected) {
      this.$emit('selected', this.item, selected);
    },
    select_selected(selected, text) {
      this.$emit('selected', this.item, selected, text);
    },
    file_selected(selected) {
      this.$emit('selected', this.item, selected);
    }
  },

  data() {
    return {
      form: {
        name: 'Testform',
        description: 'Test form description',
        questions_asked: [
          {
            id: 1,
            type: 'RADIO',
            name: 'Hur många gånger har du vandrat?',
            options: [
              {
                text: '1',
                value: 1
              },
              {
                text: 'Vill ej uppge',
                value: null
              }
            ]
          },
          {
            id: 2,
            type: 'CHECKBOX',
            name: 'Vad möttes ni av?',
            options: [
              {
                text: '1',
                value: 1
              },
              {
                text: '2',
                value: 2
              },
            ]
          },
          {
            id: 3,
            type: 'FILE',
            name: 'Ladda upp eventuella bilder',
          },

          {
            id: 4,
            type: 'TEXT',
            name: 'Svara med text',
          },
          
        ]
      }
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
