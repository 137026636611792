<template>
  <div class="no-padding">
    <label class="question-label">{{ item.name }}</label>


    <p>This is a file upload</p>
  </div>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';


export default {

  name: 'QuestionFileUploadOption',
  props: ['item'],
  
  mixins: [ toasts ],

  components: {
    
  },

  watch: {

    item: {
      deep: true,
      handler(val) {
        this.selected = null;
      }
    },
  },

  computed: {
    
  },

  mounted() {
    
  },

  methods: {

  },

  data() {
    return {
      selected: null
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.question-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

</style>
