<template>
  <div>

    <div v-if="isLoading" class="loading-container">
      <p>Laddar formulär...</p>
    </div>

    <!-- Show Error Message if Form Fails to Load -->
    <div v-else-if="hasError" class="error-container">

      <div class="error-message-box">
        <i class="fa fa-minus-circle error-icon"></i>
        <p class="error-text">{{ errorMessage }}</p>
        <button class="btn btn-primary retry-button" @click="load_form">Försök igen</button>
      </div>
    </div>


    <!-- Show Form Only If Loaded Successfully -->
    <div class="form-container" v-else-if="!submission_complete">
      <MemberLoginModal ref="member-login-modal" @authenticated="authenticated" />

      <!--<div class="account-container">
        <div v-if="isMemAuthenticated">
          <p v-if="member">Inloggad som {{ member.firstname }} {{ member.lastname }} {{ member.email }}</p>
        </div>
        <div v-else>
          <h4 style="font-weight: 400; color: #595959;">Har du ett konto?</h4>
          <div class="mt-4">
            <button class="btn btn-primary full-width" @click="login">Logga in</button>
          </div>
          <p class="mt-4"><a :href="register_link">Registrera konto</a></p>
          <p class="mt-5">Eller så kan du fortsätta utan att logga in</p>
        </div>
      </div>-->

      <div class="form-content">
        <h2>{{ form.name }}</h2>
        <p class="description">{{ form.description }}</p>

        <!--<div v-if="isMemAuthenticated">
          <p v-if="member_company" class="text-center" style="font-weight: bold;">Din grupp: {{ member_company.name }}</p>
        </div>
        <div v-else>-->
          <p class="font-weight-bold">Hitta vandringsgrupp</p>
          <QuestionSearchInput 
            :companies="companies"
            @selected="company_selected"
          />

          
          <memlist-text-input
            class="mt-8"
            id="email"
            name="email"
            :title="$t('MEMBER.EMAIL')"
            layout="vertical"
            size="lg"
            validation_type="EMAIL"

            v-model="email"

            :placeholder="$t('MEMBER.EMAIL')"
            :required="true"
            >
          </memlist-text-input>
        <!--</div>-->

        <div v-if="!company_id">
          <p style="color: red;">Måste välja grupp innan du kan fortsätta</p>
        </div>

        <div v-else>
          <div v-for="(question_asked, index) in form.items" :key="index" class="question-wrapper">
            <QuestionAsked 
              :item="question_asked" 
              class="mt-8" 
              @selected="answer_selected"
            />
          </div>

          <button class="btn btn-success submit-button" @click="submit_form">Skicka in</button>
        </div>

        
      </div>
    </div>

    <!-- Success or Failure Message -->
    <div v-else class="result-message">
      <div class="message-container">
        <i v-if="submission_success" class="fa fa-check-circle success-icon"></i>
        <i v-else class="fa fa-minus-circle error-icon"></i>
        <p class="mt-8 message-text">{{ submission_message }}</p>
        <button v-if="!submission_success" class="btn btn-primary return-button" @click="reset_form">Tillbaka</button>
      </div>
    </div>

  </div>
  
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import QuestionAsked from '@/view/components/questions/QuestionAsked.vue';
import QuestionSearchInput from '@/view/components/questions/QuestionSearchInput.vue';
import MemberLoginModal from '@/view/components/auth/MemberLoginModal.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'PublicQuestionForm',
  mixins: [ toasts ],
  components: {
    QuestionAsked,
    QuestionSearchInput,
    MemberLoginModal
  },
  computed: {
    ...mapGetters(['isMemAuthenticated']),
    register_link() {
      return `/register/${this.system.company_id}`;
    }
  },
  data() {
    return {
      company_id: null,
      linkstr: null,
      email: null,
      member_company: null,
      member: null,

      submission_complete: false,
      submission_success: false,
      submission_message: '',
      isLoading: true, // Loader state
      hasError: false, // Error state
      errorMessage: "", // Error message
      answers: {},
      system: {},
      companies: [],

      form: {}
    };
  },
  mounted() {
    this.linkstr = this.$route.params.linkstr;

    // add loader while the form is loading
    this.load_form();
    this.fetch_public();
    this.fetch_companies();

    // in unable to load form, show clear error on the page and nothing else
  },

  watch: {
    isMemAuthenticated: {
      handler(newVal) {
        if (newVal) {
          this.get_me();
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {

    async get_me() {
      const res = await axios.get(`/member/me`);

      if (res.status === 200) {
        this.member = res.data.member;

        console.log(this.member);

        if (!this.member.member_companies || this.member.member_companies.length === 0) {
          console.log('no compniaes')
          this.member_company = {
            company_id: null,
            name: 'Inget medlemskap kopplat'
          }
        }
        else {
          this.member_company = {
            company_id: this.member.member_companies[0].company_id,
            name: this.member.member_companies[0].company.name,
          }

          this.company_id = this.member_company.company_id;
        }
      }
      
    },

    async post_answers() {

      let url = `/question_form/answer/member/simple/${this.linkstr}`;
      let body = {
        answers: this.answers,
        company_id: this.company_id,
        email: this.email,
      }

      if (!this.isMemAuthenticated) {
        url = `/question_form/answer/public/simple/${this.linkstr}`;
      }

      const res = await axios.post(url, body);

      if (res.status === 201) {
        this.submission_success = true;
        this.submission_complete = true;
        this.submission_message = "Tack för din medverkan!"; 
      }
      else {
        this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skicka svar');
      }
      
    },

    answer_selected(item /* question askes*/, selected /* value selected */, text) {
      console.log('answer_selected', item, selected, text);

      this.answers[item.id+''] = {
        text: item.text || item.name,
        freetext: text,
        value: Array.isArray(selected) ? selected.join(', ') : (selected || '')
      }

      this.answers = { ...this.answers };
    },
    company_selected(company_id) {
      this.company_id = company_id;
    },

    async fetch_companies() {
      const res = await axios.get(`/company/publiclist`);

      if (res.status === 200) {
        console.log(res.data);

        this.companies = res.data;
      }
    },

    async fetch_public() {
      const res = await axios.get(`/system/public`);

      if (res.status === 200) {
        console.log(res.data);

        this.system = res.data;
      }
    },

    login() {
      this.$refs['member-login-modal'].show();
    },

    async load_form() {
      try {
        const res = await axios.get(`/question_form/published/${this.linkstr}`);

        if (res.status === 200) {
          this.form = res.data;
          this.isLoading = false; // Stop loader
          this.hasError = false;
        } else {
          throw new Error("Misslyckades att ladda formuläret.");
        }
      } catch (error) {
        console.error(error)
        this.isLoading = false;
        this.hasError = true;
        this.errorMessage = "Formuläret kunde inte laddas. Kontrollera din anslutning och försök igen.";
      }
    },

    reset_form() {
      this.submission_complete = false;
      this.submission_message = "";
      this.submission_success = false;
    },

    async submit_form() {
      await this.post_answers();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  background: #ffffff;
  max-width: 600px;
  width: 100%;
  margin: auto;
}

.account-container,
.form-content {
  width: 100%;
  max-width: 600px;
}

/* Default: Full width on mobile */
.full-width, .submit-button {
  width: 100%;
  padding: 12px;
  margin-top: 24px;
  font-size: 18px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: block;
  text-align: center;
}

/* Submit button (Green) */
.submit-button {
  background-color: #28a745;
  color: white;
}

.submit-button:hover {
  background-color: #218838;
}

/* Login button (Blue) */
.full-width {
  background-color: #007bff;
  color: white;
}

.full-width:hover {
  background-color: #0056b3;
}

/* Ensure buttons are full width only on small screens */
@media (min-width: 768px) {
  .full-width, .submit-button {
    max-width: 300px; /* Restrict width on larger screens */
  }
}


.result-message {
  text-align: center;
  padding: 40px;
}

.success-icon {
  font-size: 96px;
  color: #8dda8d;
}

.error-icon {
  font-size: 42px;
  color: #fe0000;
}

.result-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  text-align: center;
}

.message-container {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  max-width: 500px;
  width: 100%;
}

.success-icon {
  font-size: 96px;
  color: #28a745;
}

.error-icon {
  font-size: 96px;
  color: #dc3545;
}

.message-text {
  font-size: 18px;
  color: #333;
  margin: 20px 0;
}

.return-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 20px auto 0;
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.return-button:hover {
  background-color: #0056b3;
}

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  text-align: center;
}

.error-message-box {
  background: #fff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  max-width: 500px;
  width: 100%;
}

.error-icon {
  font-size: 96px;
  color: #dc3545;
}

.error-text {
  font-size: 18px;
  color: #333;
  margin: 20px 0;
}

.retry-button {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 20px auto 0;
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.retry-button:hover {
  background-color: #0056b3;
}



</style>
