<template>
  <div class="no-padding" v-if="item">
    <memlist-select-input2
      id="dropdown_input"
      name="dropdown_input"
      :title="item.name"
      layout="vertical"
      validation_type="TEXT"
      v-model="selectedValue"
      :items="dropdown_options"
      :required="item.is_mandatory"
      :invalid_text="''"
      :info_text="''"
      @update:value="updateInput"
    />
  </div>
</template>

<script>
export default {
  name: 'QuestionDropdownInput',
  props: {
    item: Object,
    modelValue: String // Parent should pass the selected value
  },

  data() {
    return {
      selectedValue: this.modelValue || '',
      dropdown_options: this.item.options || [] // Ensure options exist
    };
  },

  watch: {
    modelValue(newVal) {
      this.selectedValue = newVal;
    }
  },

  methods: {
    updateInput(newValue) {
      this.selectedValue = newValue;
      this.$emit('update:modelValue', newValue); // Sync value with parent
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";


:deep(.ml-select-input2) {
  width: 100% !important; /* Force full width */
  max-width: 100% !important;
  display: block !important; /* Ensure it behaves like a block element */
}

</style>
