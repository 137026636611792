<template>
  <div class="no-padding">
    <label class="question-label">{{ item.name }}</label>

    <div class="box">
      <div class="box-header">
        <div class="memlist-date-picker ml-date-picker">
          <date-picker
            :class="state_class"
            :disabled="disabled"
            placeholder="Välj datum..."
            format="YYYY-MM-DD"
            v-model="selectedDate"
            valueType="format"
            @change="onDateChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import dayjs from 'dayjs';

export default {
  name: 'QuestionDateOption',
  props: ['item', 'disabled'],
  
  components: {
    DatePicker
  },

  data() {
    return {
      selectedDate: null,
      localState: null
    };
  },

  computed: {
    state_class() {
      if (this.localState === null) return '';
      return this.localState ? 'success' : 'failure';
    }
  },

  watch: {
    selectedDate(newVal) {
      this.localState = newVal ? dayjs(newVal).isValid() : null;
    }
  },

  methods: {
    onDateChange() {
      this.$emit('selected', this.selectedDate);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";

.question-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Box wrapper */
.box {
  padding: 15px;
  padding-top: 20px;
  border: 1px solid #ececec;
  transition: border-color 0.3s, background-color 0.3s;
  display: flex;
  align-items: center;
}

.box-header {
  display: flex;
  align-items: center;
  width: 100%;
}

/* DatePicker styling */
.memlist-date-picker {
  width: 100%;
}

:deep(.success input) {
  border: 1px solid #1BC5BD;
}

:deep(.failure input) {
  border: 1px solid #fd397a;
}

:deep(.mx-input) {
  height: calc(1.5em + 1.3rem + 1px);
  cursor: pointer;
}
</style>
